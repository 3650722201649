import { render, staticRenderFns } from "./PodcList.vue?vue&type=template&id=153d2ae2&scoped=true&"
import script from "./PodcList.vue?vue&type=script&lang=js&"
export * from "./PodcList.vue?vue&type=script&lang=js&"
import style0 from "./PodcList.vue?vue&type=style&index=0&id=153d2ae2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "153d2ae2",
  null
  
)

export default component.exports